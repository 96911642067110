<template>
  <div>
    <v-container fluid class="background">
      <v-row style="max-width: 1080px" class="mx-auto my-12">
        <v-col cols="12" align-self="center">
          <v-card class="transparent" style="" flat>
            <v-card-title class="font-weight-bold display-1">
              어제, 오늘 그리고 내일
            </v-card-title>
            <v-card-subtitle class="subtitle-1">
              세상이 바라보는 메이드올
            </v-card-subtitle>
            <v-card-text class="body-1"> </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-data-iterator
            class="body-2"
            :loading="fetchLoading"
            :items="items"
            :page.sync="page"
            :options.sync="options"
            :server-items-length="totalCount"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            no-data-text="데이터가 없습니다"
            disable-sort
          >
            <template v-slot:loading>
              <v-row>
                <v-col v-for="index in 4" :key="index" cols="6" md="3">
                  <v-skeleton-loader class="rounded-lg" type="card" />
                </v-col>
              </v-row>
            </template>

            <template v-slot:default="props">
              <v-row>
                <v-col cols="12" v-if="fetchLoading" class="text-center">
                  <v-row>
                    <v-col v-for="index in 4" :key="index" cols="12" md="4">
                      <v-skeleton-loader class="rounded-lg" type="card" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-else
                  v-for="item in props.items"
                  :key="item.name"
                  cols="12"
                  md="4"
                >
                  <press-card :content="item" />
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>

          <div class="mt-5">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import setMeta from "@/utils/setMeta";
import PressCard from "@/components/Card/PressCard.vue";
/* eslint-disable vue/no-unused-components */
export default {
  components: { PressCard },
  beforeCreate() {
    setMeta({
      title: "소식",
      description: "메이드올의 새로운 소식을 만나보세요.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Fpress.png?alt=media&token=0e1a25a8-77b9-42e2-abfa-edb955ef6b55",
    });
  },
  data() {
    return {
      fetchLoading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      totalCount: 0,
      items: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.list();
      },
      deep: true,
    },
    search(n, o) {
      if (n !== o) this.list();
    },
  },
  methods: {
    list() {
      this.fetchLoading = true;
      this.$axios
        .get("press/collection", {
          params: {
            offset: this.page > 0 ? (this.page - 1) * this.itemsPerPage : 0,
            limit: this.itemsPerPage,
            order: this.options.sortBy[0],
            sort: this.options.sortDesc[0] ? "desc" : "asc",
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.totalCount = data.totalCount;
          this.items = data.items;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        })
        .finally(() => {
          this.fetchLoading = false;
        });
    },
  },
};
</script>

<style></style>
